import React, { useState, useEffect, useCallback } from 'react';
import BoxFiltro from './components/box-filtro';
import EventTable from './components/event-table';
import { useAuth } from "./context/auth";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const Eventi = ({start}) => {

  const [arrayEvents, setArrayEvents] = useState([]);
  const [arrayEventsUnsent, setArrayEventsUnsent] = useState([]);
  const [isRenderedList, setIsRenderedList] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().format("DD-MM-YYYY"));
  const [filterDate, setFilterDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState({sync: false, syncIntra: false, delete: false, searchEvent: false});

  const { eventsAdmin, eventsUnsentAdmin, deleteEvent, getEvent, adminSyncEvent } = useAuth();

  const onChangeDate = (date) => {
    setSelectedDate(dayjs(date).format("DD-MM-YYYY"));
  }

  const filterEvents = () => {
    setIsRenderedList(false);
    setFilterDate(dayjs(selectedDate,"DD-MM-YYYY").format("YYYY-MM-DD"));
  }


  const clickDelete = async(i) => {
    if(window.confirm("Eliminare il contenuto e gli eventuali file allegati?")) {
      setLoading({...loading, delete: i});
      let data = await deleteEvent({id: i});
      if(data.error) {
        alert("Errore: " + data.error);
      } 
      setLoading({...loading, delete: false});
      setIsRenderedList(false);
    }
  }

  const clickSync = async (eventId, intra = false ) => {
    if(window.confirm("Sincronizzare l'evento?")) {
      if(intra) {
        setLoading({...loading, syncIntra: eventId});
      } else {
        setLoading({...loading, sync: eventId});
      }
      let data = await adminSyncEvent({id: eventId, intra });
      if(data.error) {
        alert("Errore: " + data.error);
      }
      setLoading({...loading, syncIntra: false, sync: false});
      setIsRenderedList(false);
    }
  }

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  }

  const submit = async(event) => {
    event.preventDefault();
    if(searchInput) {
      setLoading({...loading, searchEvent: true});
      let data = await getEvent("?id="+ searchInput);
      if(data.event && data.event.sent) {
        setArrayEvents([data.event])
      } else {
        setArrayEvents([]);
      }
      setLoading({...loading, searchEvent: false});
    } else {
      setIsRenderedList(!isRenderedList);
    }
  }

  const callApi = useCallback(async(date) => {
    let data = await eventsAdmin("?startDate=" + date);
    setArrayEvents(data.events);
    let dataUnsent = await eventsUnsentAdmin();
    setArrayEventsUnsent(dataUnsent.events);
    setIsRenderedList(true);
  }, [eventsAdmin, eventsUnsentAdmin])

  useEffect(()=> {
    if(!isRenderedList) {
      callApi(filterDate);
    }
  },[callApi, isRenderedList, filterDate, start]);

  useEffect(()=> {
    if(start) {
      setArrayEvents([]);
      setArrayEventsUnsent([]);
      setIsRenderedList(false);
      setSelectedDate(dayjs().format("DD-MM-YYYY"));
      setFilterDate(dayjs().format("YYYY-MM-DD"));
      setSearchInput("");
    }
  }, [start]);

  return (
    <>
      <BoxFiltro date={selectedDate} onChangeDate={onChangeDate} filterEvents={filterEvents} handleChange = {handleChange} searchInput = {searchInput} submit = {submit} loading = {loading}/>
      {/* eventi non ancora trasmessi */}
      <div className="box-contenuto">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {isRenderedList && arrayEvents.length > 0 && (
                <div className="box-contenuto__wrapper">
                
                  <div className="box-contenuto__title">
                    » Elenco degli eventi trasmessi alla redazione «
                  </div>
                  <EventTable arrayEvents = {arrayEvents} deleteEvent = {clickDelete} syncEvent={clickSync} loading = {loading}/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* eventi trasmessi alla redazione */}
      <div className="box-contenuto">
        <div className="container">
          <div className="row">
            <div className="col-12">
            {isRenderedList && arrayEventsUnsent.length > 0 && (
              <div className="box-contenuto__wrapper box-contenuto__wrapper--yellow">
                <div className="box-contenuto__title">
                  » Elenco degli eventi inseriti, ma non ancora trasmessi alla redazione «
                </div>
                <EventTable arrayEvents = {arrayEventsUnsent} deleteEvent = {clickDelete} syncEvent={clickSync} loading = {loading}/>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Eventi;
