import React from 'react';
import { useAuth } from "../context/auth";
import elimina from '../images/content/elimina.gif';

const FormAttachments = ({add, fileInput, addAttachment, handleNext, handleChangeDetail, deleteAttachment, fileDescription, file, attachments, loading, loadingDelete }) => {
  const { getUrl } = useAuth();

  // TODO: quando clicco manda alla lista eventi
  const downloadFile = async(e, id, fileName) => {
    e.preventDefault();
    let data = await getUrl("?fileId=" + id + "&fileName=" + fileName);
    window.open(data.url,'_blank');
  }
  
  return (
    <>
      {/* aggiunta allegati */}
      <div className="box-contenuto__wrapper mb-3">
        <div className="form__section">
          <div className="form__title">Aggiunta allegati</div>
          {
            <>
              {add === true &&
                <div className="form__attachment text-right">
                  <strong>Il file {file.details.name} è stato aggiunto</strong>
                </div> }
              {add === false &&
                <div className="form__attachment text-right">
                  <strong>Il file è stato eliminato.</strong>
                </div>
              }
            </>
          }
          <div className="d-flex justify-content-start">
            <label className="form__label">File:</label>
            <input id="files-upload" type="file" ref={fileInput} className="form__input form__input--file" onChange = {handleChangeDetail}/>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">(*) Descrizione:</label>
            <input type="text" className="form__input form__input--large" name="fileDescription" value={fileDescription} onChange = {handleChangeDetail}/>
            <button type="button" onClick={addAttachment}>
              {loading &&<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
              Aggiungi
            </button>
          </div>
        </div>
      </div>
      {/* bottone indietro e salva*/}
      <div className="box-contenuto__wrapper text-center mb-3">
        <button type="button" onClick={(e,string) => handleNext(e,"previous")} className="form__submit mr-2">Indietro</button>
        <button type="submit" className="form__submit ml-2">Salva e chiudi</button>
      </div>
      {/* visualizza allegati */}
      <div className="box-contenuto__wrapper">
        <div className="form__section">
          <div className="form__title">
            Allegati presenti
          </div>
          <ul className="p-0 m-0">
            {
              attachments && attachments.map((attachment) => (
              <li key={attachment.id} className={`d-flex justify-content-start`}>
                <label className="form__label">File:</label>
                <div className="text-left">
                  <div>{attachment.description}</div>
                  <div>
                  <button onClick={(e)=> downloadFile(e, attachment.id, attachment.fileName)} className="form__link">{attachment.fileName}</button>
                    <span>, {attachment.size} bytes</span>
                  </div>
                </div>
                <button type="button" onClick={(id, name) => deleteAttachment(attachment.id, attachment.fileName)} className="form__btn ml-auto mr-5">
                  {loadingDelete === attachment.id ? 
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> :
                    <img src={elimina} alt="elimina" className="ml-auto"/>
                  }  
                </button>
              </li>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
}

export default FormAttachments;