import React from 'react';
import BoxAvviso from './components/box-avviso';
import BoxWelcome from './components/box-welcome';

const Welcome = () => {
  return (
    <>
      <BoxAvviso />
      <BoxWelcome />
    </>
  );
}

export default Welcome;
