import React from 'react';
import convertCategory from '../utility/convertCategory';
import * as dayjs from 'dayjs';

const FormRecap = ({detail}) => {
  return (
    <div className="box-contenuto__wrapper mb-3">
      <div className="form__section">
        <div className="form__title">
          Evento
        </div>
        {detail.title && (
          <div className="d-flex justify-content-start">
            <label className="form__label">Titolo:</label>
            <span className="text-left">{detail.title}</span>
          </div>
        )}
        {detail.description && (
          <div className="d-flex justify-content-start">
            <label className="form__label">Descrizione:</label>
            <div dangerouslySetInnerHTML={{ __html: detail.description }} className="text-left"/>
          </div>
        )}
        <div className="d-flex justify-content-start">
          <label className="form__label">Quando:</label>
          <span className="text-left">{dayjs(detail.startDate).format("DD/MM/YYYY")} ore {detail.startTime}</span>
        </div>
        {(detail.place || detail.address || detail.city) && (
          <div className="d-flex justify-content-start">
            <label className="form__label">Dove:</label>
            <div className="text-left">
              {detail.place && (<div>{detail.place}</div>)}
              {detail.address && (<div>{detail.address}</div>)}
              {detail.city && (<div>{detail.city}</div>)}
            </div>
          </div>
        )}
        {detail.categories && (
          <div className="d-flex justify-content-start">
            <label className="form__label">Categoria:</label>
            <div className="text-left">
              {
                detail.categories.map((category, index) => (
                <div key={index}>» {convertCategory(category)}</div>
                ))
              }
            </div>
          </div>
        )}
        {detail.notes && (
          <div className="d-flex justify-content-start">
            <label className="form__label">Note:</label>
            <span className="text-left">{detail.notes}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormRecap;