import React from 'react';
import Header from "./components/header";
import Login from "./components/login";


const PaginaLogin = ({ location }) => {
  return (
    <>
      <Header/>
      <Login referer={location.state && location.state.referer} />
    </>
  );
}

export default PaginaLogin;