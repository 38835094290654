import React from 'react';
import Flatpickr from "react-flatpickr";

const BoxFiltro = ({date, onChangeDate, filterEvents, handleChange, searchInput, submit, loading}) => {

  const optionsDatePicker = {
    locale: "it",
    dateFormat: "d/m/Y",
    monthSelectorType: "static",
    defaultDate: date
  }

  return (
    <div className="box-contenuto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-contenuto__wrapper">
              <form onSubmit={submit} className="form">
                <div className="d-flex align-items-center justify-content-around">
                  <div>
                    <span className="mr-1">Visualizza eventi a partire dalla data:</span>
                    <Flatpickr value={date} options={optionsDatePicker} 
                    onChange={(date) => onChangeDate(date[0])} className="form__input"/>
                    <button type="button" onClick={filterEvents} className="form__button">Visualizza</button>
                  </div>
                  <div>
                    <span className="mr-1">Cerca singolo evento:</span>
                    <input type="text" name="eventId" value = {searchInput} onChange={handleChange} className="form__input"/>
                    <button type="submit" value="Submit" className="form__button">
                      {loading.searchEvent &&<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                      Cerca
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxFiltro;