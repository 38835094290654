import React from 'react';
import * as dayjs from 'dayjs'
import 'dayjs/locale/it'


const BoxAvviso = ({text}) => {
  dayjs.locale('it'); 

  return (
    <div className="box-contenuto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-contenuto__wrapper">
              <div className="d-flex align-items-center justify-content-center">
                <p>Non è garantita la pubblicazione puntuale per eventi programmati prima del <strong>{dayjs().add(6, 'day').format('D MMMM YYYY')}</strong>.</p>
                {text && (
                  <>{text}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxAvviso;