import React, { useState, useEffect, useCallback } from 'react';
import TabellaUtenti from './components/tabella-utenti';
import { useAuth } from "./context/auth";

const Utenti = ({start}) => {

  const [arrayUtenti, setArrayUtenti] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [loading, setLoading] = useState({promote: false});

  const { showUsersList, promoteUser } = useAuth();

  const clickPromote = async(username) => {
    // trova l'indice corrispondente alla chiave dell'elemento cliccato
    if(window.confirm("Promuovere questo utente ad amministratore?")) {
      setLoading({...loading, promote: username});
      let data = await promoteUser({username: username});
      if(data.error) {
        alert("Errore: " + data.error);
      }
      setIsRendered(false);
      setLoading({...loading, promote: false});
    }
  }

  const callApiUsers = useCallback(async()=> {
     let res = await showUsersList("?role=redattore");
     setArrayUtenti(res.users);
     setIsRendered(true);
  }, [showUsersList]);

  useEffect(()=> {
    if(start) {
      callApiUsers();
    }
  }, [start, callApiUsers]);

 
  return (
    <>
      {isRendered &&
        <TabellaUtenti arrayUtenti = {arrayUtenti} clickPromote = {clickPromote} loading = {loading}/>
      }
    </>
  );
}

export default Utenti;
