import React, { useState, useEffect, useCallback } from 'react';
import Flatpickr from "react-flatpickr";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useAuth } from "./context/auth";
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

const optionsDatePicker = {
  locale: "it",
  dateFormat: "d/m/Y",
  monthSelectorType: "static",
  defaultDate: dayjs().format("DD-MM-YYYY")
}

const Esporta = ({start}) => {
  const [arrayEvents, setArrayEvents] = useState([]);
  const [dates, setDates] = useState ({
    start: dayjs().format("DD-MM-YYYY"),
    end: dayjs().format("DD-MM-YYYY")
  });
  const [filteredArray, setFilteredArray] = useState(null);

  const { eventsAdmin, eventsUnsentAdmin} = useAuth();

  const onChangeDate = (date, str) => {
    if(str==="start") {
      setDates({...dates, start: dayjs(date).format("DD-MM-YYYY")});
    } else {
      setDates({...dates, end: dayjs(date).format("DD-MM-YYYY")});
    }
  }

  const submitForm = (event) => {
    event.preventDefault();
    setFilteredArray(arrayEvents.filter((event) => 
    dayjs(dates.end, "DD-MM-YYYY").isSameOrAfter(dayjs(event.startDate, "YYYY-MM-DD"), 'day')));
    console.log("Array filtrata", filteredArray);
  }
  
  useEffect(()=> {
    if(start) {
      setDates({
        start: dayjs("DD-MM-YYYY"),
        end: dayjs("DD-MM-YYYY")
      })
    }
  }, [start]);

  const callApi = useCallback(async(date)=> {
    let data = await eventsAdmin("?startDate=" + dayjs(date,"DD-MM-YYYY").format("YYYY-MM-DD"));
    let dataUnsent = await eventsUnsentAdmin("?startDate=" + dayjs(date,"DD-MM-YYYY").format("YYYY-MM-DD"));
    setArrayEvents(data.events.concat(dataUnsent.events));
  }, [eventsAdmin, eventsUnsentAdmin])

  useEffect(()=> {
    if(dates.start) {
      callApi(dates.start);
    }
  },[callApi, dates]);

  // chiamare l'api per l'export su filteredArray
 
  return (
    <div className="box-contenuto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-contenuto__wrapper">
              <div className="text-center pt-4 pb-2">
                <strong>Formato CSV</strong>
              </div>
              <form className="form text-center" onSubmit={submitForm}>
                <p className="mb-2">Esporta in formato CSV gli eventi programmati</p>
                <label>a partire dalla data:&nbsp;</label>
                <Flatpickr options={optionsDatePicker} onChange={(date) => onChangeDate(date[0], "start")} className="form__input"/>
                <label>fino alla data:&nbsp;</label>
                <Flatpickr options={optionsDatePicker} onChange={(date) => onChangeDate(date[0], "end")} className="form__input"/>
                <button type="submit">Esporta</button>
              </form>
              <div>
                Esportati 12 eventi.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Esporta;
