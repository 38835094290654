import React from 'react';
import { Router } from "@reach/router";
import Header from "./components/header";
import Navigation from './components/navigation';
import Nuovo from './nuovo';
import Gestisci from './gestisci';
import Istruzioni from './istruzioni';
import Modifica from './modifica';
import Welcome from './welcome';
import NotFound from './notFound';


const Redattore = () => {

  return (
    <>
      <Header/>
      <Navigation />
      <Router>
        <Welcome path="/"/>
        <Nuovo path="nuovo"/>
        <Gestisci path="gestisci"/>
        <Istruzioni path="istruzioni"/>
        <Modifica path="modifica/:eventId"/>
        <NotFound path="/notfound" default errorMessage="Pagina non trovata."/>
      </Router>
    </>
  );
}

export default Redattore;