import React from 'react';
import { Link } from "@reach/router";
import elimina from '../images/content/elimina.gif';
import invia from '../images/content/invia.png';
import vedi from '../images/content/vedi.png';
import convertCategory from '../utility/convertCategory';
import { useAuth } from "../context/auth";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const EventTable = ({arrayEvents, deleteEvent, syncEvent, sendEvent, loading}) => {
  const { user, getUrl } = useAuth();

  const downloadFile = async(e, id, fileName) => {
    e.preventDefault();
    let data = await getUrl("?fileId=" + id + "&fileName=" + fileName);
    window.open(data.url,'_blank');
  }

  let link = user.role === "admin" ? "/admin/modifica/" : "/redattore/modifica/";
  return (
    <table className="event-table">
      <thead>
        <tr>
          <th width="100">
            Quando
          </th>
          <th>
            Evento
          </th>
          {
            user.role === "admin" && 
            <th width="30">
              ComPV
            </th>
          }
          {
            user.role === "admin" && 
            <th width="30">
              INTRA
            </th>
          }
          <th width="30">
            Modifica
          </th>
          {
            user.role !== "admin" && 
            <th width="30">
              Invia
            </th>
          }
          <th width="30">
            Elimina
          </th>
        </tr>
      </thead>
      <tbody>
      { 
        arrayEvents && arrayEvents.length > 0 ?
        <>
          {
            arrayEvents.map((event) => (
              <tr key={event.id || event.eventId}>
                <td>
                  {
                    user.role &&
                    <>Data inizio<br/></>
                  }
                  {dayjs(event.startDate).format("DD/MM/YYYY")}
                  <br/>
                  ore {event.startTime}
                </td>
                <td>
                  <div className="d-flex">
                    <div className="event-table__label">
                      Id: 
                    </div>
                    <div>{event.id}</div>
                  </div>
                  <div className="d-flex">
                    <div className="event-table__label">
                      Titolo: 
                    </div>
                    <div>{event.title}</div>
                  </div>
                  <div className="d-flex">
                    <div className="event-table__label">
                      Descrizione: 
                    </div>
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: event.description }} className="text-left"/>
                    </div>
                  </div>
                  {
                    (event.place || event.address || event.city) &&
                    <div className="d-flex">
                      <div className="event-table__label">
                        Dove: 
                      </div>
                      <div>
                        <div>{event.place}</div>
                        <div>{event.address}</div>
                        <div>{event.city}</div>
                      </div>
                    </div>
                  }
                  <div className="d-flex">
                    <div className="event-table__label">
                    Categoria: 
                    </div>
                    <div>
                      {
                        event.categories.map((category, i) => (
                          <div key={i}>
                            » {convertCategory(category)}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  {event.notes &&
                    <div className="d-flex">
                      <div className="event-table__label">
                        Note: 
                      </div>
                      <div>{event.notes}</div>
                    </div>
                  }
                  {event.attachmentList && event.attachmentList.length > 0 &&
                    <div className="d-flex">
                      <div className="event-table__label">
                      Allegati: 
                      </div>
                      <div>
                        {
                          event.attachmentList.map((attachment, i) => (
                            <div key={i}>
                              <span>» </span>
                              <button onClick={(e)=> downloadFile(e, attachment.id, attachment.fileName)} className="event-table__link">{attachment.fileName}</button>
                              <span>, {attachment.size} bytes</span> <br/>
                              <span>{attachment.description}</span>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  }
                  <div>
                    {
                      user.role === "admin" ? 
                      (
                        <>
                          <div className="event-table__label">
                            Inserito da: 
                          </div>
                          {event.author.name} {event.author.surname} ({event.author.ente}),&nbsp; 
                          <a href={"mailto:"+ event.author.email} title={"Scrivi a " + event.author.name + event.author.surname}>{event.author.email}</a>
                        </>
                      ) : 
                      (
                        <div>
                          <div className="event-table__label">
                            Stato: 
                          </div>
                          {
                            event.sent === true ?
                            <>
                              Trasmesso alla redazione.
                            </> :
                            <>
                              <em>Non ancora trasmesso. </em>
                              Se completo,<button  onClick={()=> sendEvent(event.id)} className="event-table__text event-table__text--red">invia alla redazione.</button>
                            </>
                          }
                        </div>
                      )
                    }
                  </div>
                </td>
                {
                  user.role === "admin" && 
                  // sync evento
                  <td align="center">
                    <button disabled={(event.categories.includes(100) && event.categories.length === 1) ?true:false} onClick={() => syncEvent(event.id)} className="event-table__text event-table__text--red">
                      {
                        loading && loading.sync === event.id ? 
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> :
                        <>
                          {event.sync === true? <>Sì</> : <>No</>}
                        </>
                      }
                    </button>
                  </td>
                }
                {
                  user.role === "admin" && 
                  // sync evento intranet
                  <td align="center">
                    <button disabled={event.categories.includes(100)?false:true} onClick={() => syncEvent(event.id, true)} className="event-table__text event-table__text--red">
                      {
                        loading && loading.syncIntra === event.id ? 
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> :
                        <>
                          {event.syncIntra === true? <>Sì</> : <>No</>}
                        </>
                      }
                    </button>
                  </td>
                }
                <td align="center">
                  <Link to={link+event.id} className="event-table__btn">
                    <img src={vedi} alt="vedi"/>
                  </Link>
                </td>
                {
                  user.role !== "admin" && 
                  <td align="center">
                    {
                      !event.sent &&
                      <button type="button" onClick={()=> sendEvent(event.id)} className="event-table__btn">
                        {loading && loading.send === event.id ? 
                          <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> :
                          <img src={invia} alt="invia"/>
                        } 
                      </button>
                    }
                  </td>
                }
                <td align="center">
                  <button type="button" onClick={()=> deleteEvent(event.id)} className="event-table__btn">
                    {loading && loading.delete === event.id ? 
                      <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> :
                      <img src={elimina} alt="elimina"/>
                    }
                  </button>
                </td>
              </tr>
            ))
          }
        </> :
        <tr className="noevents">
          <td colSpan="5" align="center">Nessun evento.</td>
        </tr>
      }
      </tbody>
    </table>
  );
}

export default EventTable;