import React, {useCallback, useEffect, useState} from 'react';
import { useAuth } from "./context/auth";
import { Redirect } from "@reach/router";
import Header from "./components/header";
import NotFound from "./notFound";
import './css/custom.scss';


function Home({location }) {
  const { isTokenValid, user } = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(null);

  const currentPath = location.state && location.state.referer.pathname;

  const callApi = useCallback(async() => {
    let res = await isTokenValid();
    setRedirectToLogin(!res);
  }, [isTokenValid])

  useEffect(()=> {
    // Se il refresh token che ho in Local Storage non è valido, faccio una redirect alla login.
    // Se non ho refreshToken salvato in Local Storage, faccio una redirect alla login.
    if(localStorage.getItem("username") && localStorage.getItem("refreshToken")) {
      callApi();
    } else {
      setRedirectToLogin(true);
    }
  }, [callApi]);

  return (
    <>
      <Header/>
      {
        redirectToLogin === true &&
        <Redirect to="/login" state={{ referer: location.state && location.state.referer }} noThrow/>
      }
      {
        redirectToLogin === false && 
        <>
          {
            // Controllo il path inserito.
            // Se il path contiene il mio ruolo, posso accedere alla pagina.
            // La root di base è l'homepage dell'utente.
            currentPath ?
            <>
            {
              currentPath.includes(user.role) ?
              <Redirect to={currentPath} noThrow/> :
              <NotFound errorMessage = "Non autorizzato"/>
            }
            </>:
            <>
            <Redirect to={user.role} noThrow/>
            </>
          }
        </>
      }
    </>
  );
}

export default Home;
