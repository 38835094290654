import React from 'react';
import Header from "./components/header";


function NotFound({header, errorMessage}) {

  return (
    <>
    {header &&
      <Header />
    }
    <div className="box-contenuto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            Errore. {errorMessage}
          </div>
        </div>
      </div>
    </div> 
    </>     
  );
}

export default NotFound;
