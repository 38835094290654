import React, {useEffect, useState } from 'react';
import BoxAvviso from './box-avviso';
import FormInputs from './form-inputs';
import FormAttachments from './form-attachments';
import FormRecap from './form-recap';
import { navigate } from '@reach/router';
import { useAuth } from "../context/auth";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)
const Form = ({start, initialState, initialId}) => {

  const [eventDetail, setEventDetail] = useState(initialState);
  const [showMore, setShowMore] = useState(false);
  const [add, setAdd] = useState(null);
  const [error, setError] = useState({isError : false, message : ""});
  const [eventKey, setEventKey] = useState(initialId);
  const [file, setFile] = useState(null);
  const [fileDescription, setFileDescription] = useState("");
  const [attachments, setAttachments] = useState(eventDetail.attachmentList);
  const [isEventModified, setIsEventModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  let fileInput = React.createRef();
  const { user, createNewEvent, modifyEvent, prepareFileUpload, confirmFileUpload, deleteAtt } = useAuth();

  const handleNext = (option) => {
    setAdd(null);
    if(option === "next") {
      controlFormInput();
    } else {
      setShowMore(false);
    }
  }

  // gestione degli input del form
  const handleChangeDetail = (event) => {
    setIsEventModified(true);
    switch(event.target.type) {
      case 'checkbox':
        if(event.target.checked) {
          setEventDetail({
            ...eventDetail,
            categories : [...eventDetail.categories, parseInt(event.target.value)].sort((a,b)=>a-b)
          })
        } else {
          let copiedArray = [...eventDetail.categories];
          copiedArray.splice(eventDetail.categories.indexOf(parseInt(event.target.value)), 1); 
          setEventDetail({
            ...eventDetail,
            categories : copiedArray
          })
        }
      break;
      case 'file':
        if(fileInput.current.files[0]) {
          setFile({details: fileInput.current.files[0]})
          let reader = new FileReader();
          reader.readAsDataURL(fileInput.current.files[0]);
        };
      break;
      case 'text':
        if(event.target.name === "fileDescription") {
          setFileDescription(event.target.value);
        } else {
          setEventDetail({
            ...eventDetail,
            [event.target.name] : event.target.value,
          });
        }
      break;
      default:
        setEventDetail({
          ...eventDetail,
          [event.target.name] : event.target.value,
        });
    }
  }

  const handleChangeTextArea = (value) => {
    setIsEventModified(true);
    setEventDetail({
      ...eventDetail,
      description: value
    });
  }

  // gestione degli input del datepicker
  const onChangeDate = (date, arg) => {
    setIsEventModified(true);
    let formattedDate = (arg === "startDate" || arg === "endDate") ? dayjs(date).format("YYYY-MM-DD") : dayjs(date).format('HH:mm');
    setEventDetail({
      ...eventDetail,
      [arg]: formattedDate
    });
  }

  // controlli del form e submit della prima parte
  const controlFormInput = () => {
    if(!eventDetail.description === "<p></p>" || eventDetail.description === "") {
      alert("Descrizione obbligatoria.");
      return
    }

    if (eventDetail.startDate && eventDetail.startTime) {
      let errorStartDate = dayjs().isAfter(dayjs(eventDetail.startDate,"YYYY-MM-DD"),'day');
      let errorEndDate = dayjs(eventDetail.endDate, "YYYY-MM-DD").isBefore(dayjs(eventDetail.startDate,"YYYY-MM-DD"),'day');
      // se non ho errori, chiamo il servizio crea/modifica evento
      if(!errorStartDate && !errorEndDate && eventDetail.categories.length > 0) {
        callServiceCreateUpdate();
      } else if(errorStartDate) {
        setError({
          isError : true,
          message: "ERRORE: la data di inizio " + dayjs(eventDetail.startDate, "YYYY-MM-DD").format("DD-MM-YYYY") + " è già passata, contenuto non inserito." 
        });
      } else if(errorEndDate) {
        setError({
          isError : true,
          message: "ERRORE: la data di scadenza " + dayjs(eventDetail.endDate, "YYYY-MM-DD").format("DD-MM-YYYY") + " precede quella di inizio " + dayjs(eventDetail.startDate, "YYYY-MM-DD").format("DD-MM-YYYY") + ", contenuto non inserito."
        });
      } else {
        setError({
          isError : true,
          message: "ERRORE: è necessario selezionare almeno una categoria valida di appartenenza, contenuto non inserito."
        });
      }
    } else {
      if(!eventDetail.startDate) {
        alert("Campo Data inizio obbligatorio.");
      } else {
        alert("Campo Ora inizio obbligatorio.");
      }
    }
  }

  const callServiceCreateUpdate = async() => {
    if(!eventKey) {
      setLoading(true);
      let data = await createNewEvent(eventDetail);
      setEventKey(data.eventId);
      setShowMore(true);
      setError({isError : false, message: "" });
      setIsEventModified(false);
      setLoading(false);
    } else {
      // chiamo il servizio solo se i campi sono stati effettivamente modificati
      if(isEventModified) {
        setLoading(true);
        await modifyEvent({...eventDetail, id: eventKey});
        setShowMore(true);
        setError({isError : false, message: "" });
        setIsEventModified(false);
        setLoading(false);
      } else {
        setShowMore(true);
      }
    }
  }

  const addAttachment = async() => {
    if(file) {
      if(fileDescription === "") {
        alert("Compilare la descrizione del file");
      } else {
        setLoading(true);
        try {
        let {data, fileId} = await prepareFileUpload({id: eventKey, filename: file.details.name});
        const formData = new FormData();
        formData.append("Content-Type", file.details.type);
        Object.entries(data.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", file.details);
        await fetch(data.url, {
          method: 'post',  
          body: formData
        })
        let res = await confirmFileUpload({fileId, fileName: file.details.name, description: fileDescription});
        setAttachments(res.event.attachmentList);
        setFileDescription("");
        setAdd(true);
        setLoading(false);
        // reset dell'input del file
        document.getElementById('files-upload').value = null;
        } catch(err) {
          console.log("Errore!", err);
        };
      }
    } else {
      alert("Nessun allegato selezionato.");
    }
  }

  const deleteAttachment = async(i, name) => {
    if(window.confirm("Vuoi eliminare il file allegato " + name + "?")) {
      setLoadingDelete(i);
      const data = await deleteAtt({fileId: i});
      setAdd(false);
      setLoadingDelete(false);
      setAttachments(data.event.attachmentList);
    }
  }
  
  const submitForm = (e) => {
    e.preventDefault();
    e.target.reset();
    // setRemovedItem(null);

    if(!showMore) {
      handleNext("next");
    } else {
      let link = user.role === "admin" ? "/admin" : "/redattore/gestisci";
      navigate(link);
    }
  }

  useEffect(()=> {
    if(start) {
      setEventDetail(initialState);
      setShowMore(false);
      setError({isError: false, message: ""});
      setEventKey(null);
      setAdd(null);
    }
  }, [start, initialState]);


  return (
    <>
    {!error.isError ?
      <>
        {!showMore ? 
          <BoxAvviso text = {<p className="ml-2">Il simbolo <strong>(*)</strong> indica un campo obbligatorio.</p>}/> :
          <div className="box-contenuto">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="box-contenuto__wrapper">
                    <p>In questa pagina, puoi allegare uno o più file utilizzando il modulo sotto riportato.</p>
                    {
                      user.role === "admin" ? 
                      <p>Una volta terminato, puoi <strong>inviarlo</strong> alla redazione oppure <strong>salvarlo</strong> come bozza per eventuali modifiche successive.</p> :
                      <p>Una volta terminato, puoi <strong>salvarlo</strong> per inviarlo successivamente alla redazione.</p>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="box-contenuto">
          <div className="container">
            <div className="row">
              <div className="col-12">
                  <form className="form" onSubmit = {(e) => submitForm(e)}>
                    <div className={`${showMore?'d-none':'d-block'}`}>
                      <FormInputs eventDetail =  {eventDetail} onChangeDate = {onChangeDate} handleChangeDetail = {handleChangeDetail} handleChangeTextArea = {handleChangeTextArea} loading = {loading}/>
                    </div>
                    <div className={`${showMore?'d-block':'d-none'}`}>
                      <FormRecap detail = {eventDetail}/>
                      <FormAttachments add = {add} fileInput = {fileInput} addAttachment = {addAttachment} handleNext = {handleNext} handleChangeDetail = {handleChangeDetail} 
                        deleteAttachment = {deleteAttachment} fileDescription = {fileDescription} file={file} attachments = {attachments} loading = {loading} loadingDelete = {loadingDelete}/>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </> :
      <>
        <BoxAvviso text = {<p className="ml-2">Il simbolo <strong>(*)</strong> indica un campo obbligatorio.</p>}/>
        <div className="box-contenuto">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="box-contenuto__wrapper p-4">
                  <div className="text-center mb-1">
                    <strong>{error.message}</strong>
                  </div>
                  <div className="text-center mt-1">
                    È possibile tornare alla 
                    <button type="button" onClick={()=>{setError({isError: false, message: ""})}} className="box-contenuto__btn">pagina precedente</button>
                    per reinserirlo, 
                    {
                      eventDetail.categories.length > 0 ?
                      <> prestando attenzione alle date.</> :
                      <> dopo aver selezionato la categoria corretta.</>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    }
    </>
  );
}

export default Form;