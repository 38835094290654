import React from 'react';
import { Link } from "@reach/router";
import { useAuth } from "../context/auth";

const linkLineeGuida = "https://app-comune-pavia-files-prod.s3-eu-west-1.amazonaws.com/static/Linee+guida+per+la+redazione+dei+testi+del+sito+del+Comune+di+Pavia.pdf";

const Navigation = () => {
  const { setUser, user } = useAuth();

  const handleClick = () => {
    if(window.confirm("Chiudere la sessione?")) {
      setUser(null);
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("username");
    }
  }

  return (
    <div className="navigation">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="navigation__list d-flex justify-content-around">
              {
                user.role === "admin" ? 
                <>
                  <li className="navigation__item">
                    <Link to="" className="navigation__link">Eventi</Link>
                  </li>
                  <li className="navigation__item">
                    <Link to="esporta" className="navigation__link">Esporta</Link>
                  </li>
                  <li className="navigation__item">
                    <Link to="amministratori" className="navigation__link">Amministratori</Link>
                  </li>
                  <li className="navigation__item">
                    <Link to="utenti" className="navigation__link">Utenti</Link>
                  </li>
                </> :
                <>
                  <li className="navigation__item">
                    <Link to="nuovo" className="navigation__link">Nuovo</Link>
                  </li>
                  <li className="navigation__item">
                    <Link to="gestisci" className="navigation__link">Gestisci</Link>
                  </li>
                </>
              }
              <li className="navigation__item">
                <a href={linkLineeGuida} target="_blank" rel="noopener noreferrer" className="navigation__link">
                  Linee guida per la redazione dei testi
                </a>
              </li>
              <li className="navigation__item">
                <Link to="istruzioni" className="navigation__link">Istruzioni</Link>
              </li>
              <li className="navigation__item">
                <button to="nuovo" className="navigation__link" onClick={handleClick}>Chiudi sessione</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;