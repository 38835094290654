import React, {useState} from 'react';
import { Redirect} from "@reach/router";
import { useAuth } from "../context/auth";


const Login = ({ referer }) => {
  const [loginParams, setLoginParams] = useState({
    username: "",
    password: ""
  });
  const [errorAuthentication, setErrorAuthentication] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoginParams({
      ...loginParams,
      [event.target.name] : event.target.value,
    });
  }

  const { setUser, user, setToken } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await fetch('/api/login', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },    
        body: JSON.stringify({
          username: loginParams.username,
          password: loginParams.password,
        })
      })
      let data = await response.json();
      if(!data.error) {
        setToken(data.token);
        setUser(data.user);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("username", data.user.username);
      }
      else {
        setErrorAuthentication(true);
      }
    } catch(err) {
      console.log("Error:", err);
    }
    setLoading(false);
  }

  // controlla il tipo di utente
  if(user) {
    if (referer && referer.pathname !== '/') {
      if(referer.pathname.split("/")[1] === user.role) {
        return <Redirect to={referer.pathname} noThrow/>
      } else {
        if(user.role === "admin") {
          return <Redirect to="/admin" noThrow/>
        } else {
          return <Redirect to="/redattore" noThrow/>
        }
      }
    } else if (user.role === "admin") {
      return <Redirect to="/admin" noThrow/>
    } else {
      return <Redirect to="/redattore" noThrow/>
    }
  }

  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="login__wrapper">
              <div className="login__title">
                Gestione contenuti sito
              </div>
              <p className="login__text">
                Inserire lo username e la relativa password per accedere all'area riservata.
              </p>
              <form onSubmit = {handleSubmit} className = "login__form">
                <div className="d-flex align-items-start justify-content-center">
                  <label className="login__label">
                    <strong>Username:</strong>
                  </label>
                  <div className="login__input-wrapper d-flex">
                    {/* TODO: aggiungi required */}
                    <input className = "login__input" type="text" name = "username" onChange= {handleChange}/>
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-center">
                  <label className="login__label">
                    <strong>Password:</strong>
                  </label>  
                  <div className="login__input-wrapper d-flex">
                    {/* TODO: aggiungi required */}
                    <input className = "login__input" type="password" name = "password" onChange= {handleChange}/>
                    <button className = "login__input" type = "submit" value="Submit">
                      {loading &&<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                      Login
                    </button>
                  </div>
                </div>
              </form>
              {
                errorAuthentication &&
                <p className="login__text login__text--red">
                  Attenzione, username e password non validi
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;