import React from 'react';

const BoxWelcome = () => {
  return (
    <div className="box-contenuto box-contenuto--large">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-contenuto__wrapper">
              <div className="d-flex align-items-center justify-content-around">
                <h1>Benvenuto nel pannello di inserimento contenuti.</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxWelcome;