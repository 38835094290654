import React from 'react';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/l10n/it.js';
import "flatpickr/dist/themes/material_blue.css";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import EditorDocument from './editor-document';

dayjs.extend(customParseFormat);

const FormInputs = ({eventDetail, onChangeDate, handleChangeDetail, handleChangeTextArea, loading}) => {

  const optionsDatePicker = {
    pickerDate : {
      locale: "it",
      dateFormat: "d/m/Y",
      monthSelectorType: "static",
      defaultDate: null
    },
    pickerTime : {
      locale: "it",
      noCalendar: true,
      enableTime: true,
      time_24hr: true,
      defaultDate: null,
      defaultHour: "8"
    }
  }

  const isChecked = (category) => {
    return eventDetail.categories.includes(category);
  }

  return (
    <>
      <div className="box-contenuto__wrapper mb-3">
        {/* Sezione form date */}
        <div className="form__section">
          <div className="form__title">
            Quando
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">(*) Data inizio:</label>
            <Flatpickr className="form__input" value={eventDetail.startDate ? dayjs(eventDetail.startDate,"YYYY-MM-DD").format("DD-MM-YYYY") : null} options={optionsDatePicker.pickerDate} 
              onChange={(date) => onChangeDate(date[0], "startDate")}/>
            <em>(es.: 25/12/2020, cliccare per selezionare da calendario)</em>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">(*) Ora inizio:</label>
            <Flatpickr className="form__input" value={eventDetail.startTime} options={optionsDatePicker.pickerTime}  
              onClose={(date) => onChangeDate(date[0], "startTime")} />
            <em>(es.: 09:00)</em>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">Data fine:</label>
            <Flatpickr className="form__input" value={eventDetail.endDate ? dayjs(eventDetail.endDate,"YYYY-MM-DD").format("DD-MM-YYYY") : null} options={optionsDatePicker.pickerDate}
              onChange={(date) => onChangeDate(date[0], "endDate")} />
            <em>(es.: 25/12/2020, cliccare per selezionare da calendario)</em>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">Ora fine:</label>
            <Flatpickr className="form__input" value={eventDetail.endTime} options={optionsDatePicker.pickerTime} 
              onClose={(date) => onChangeDate(date[0], "endTime")} />
            <em>(es.: 17:00, non compilare se non è previsto un orario di fine evento)</em>
          </div>
        </div>
        {/* Sezione form luogo */}
        <div className="form__section">
          <div className="form__title">
            Luogo
          </div>
          <div className="d-flex justify-content-start mb-1">
            <label className="form__label"></label>
            <em>Compilare la sezione Luogo se si desidera che compaia la posizione sulla mappa.</em>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">Nome:</label>
            <input className="form__input form__input--large" type="text" name="place" value={eventDetail.place} onChange = {handleChangeDetail}/>
            <em>(es.: Palazzo Mezzabarba)</em>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">Via e numero civico:</label>
            <input className="form__input form__input--large" type="text" name="address" value={eventDetail.address} onChange = {handleChangeDetail}/>
            <em>(es.: Piazza Municipio 2)</em>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">Comune:</label>
            <input className="form__input form__input--large" type="text" name="city" value={eventDetail.city} onChange = {handleChangeDetail}/>
            <em>(es.: Pavia)</em>
          </div>
        </div>
        {/* Sezione form dettagli */}
        <div className="form__section">
          <div className="form__title">
            Dettagli
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">(*) Titolo:</label>
            <input className="form__input form__input--large" type="text" name="title" value={eventDetail.title} onChange = {handleChangeDetail} required={true}/>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">(*) Descrizione:</label>
            <div className="form__container">
              <EditorDocument handleChangeTextArea = {handleChangeTextArea} description = {eventDetail.description}/>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label mb-0">(*) Categoria:</label>
            <div>
              <div className="text-left">
                <input type="checkbox" name="AMMINISTRAZIONE" value={1} onChange = {handleChangeDetail} checked = {isChecked(1)}/>
                <span>AMMINISTRAZIONE</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="MOBILITA' E TRASPORTI" value={2} onChange = {handleChangeDetail} checked = {isChecked(2)}/>
                <span>MOBILITA' E TRASPORTI</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="SCUOLA, GIOVANI E FAMIGLIA" value={3} onChange = {handleChangeDetail} checked = {isChecked(3)}/>
                <span>SCUOLA, GIOVANI E FAMIGLIA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="TASSE E TRIBUTI" value={4} onChange = {handleChangeDetail} checked = {isChecked(4)}/>
                <span>TASSE E TRIBUTI</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="DUC" value={5} onChange = {handleChangeDetail} checked = {isChecked(5)}/>
                <span>DUC</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="COMMERCIO E ATTIVITA' PRODUTTIVE" value={6} onChange = {handleChangeDetail} checked = {isChecked(6)}/>
                <span>COMMERCIO E ATTIVITA' PRODUTTIVE</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="LAVORI PUBBLICI E URBANISTICA" value={7} onChange = {handleChangeDetail} checked = {isChecked(7)}/>
                <span>LAVORI PUBBLICI E URBANISTICA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="SERVIZIO EDILIZIA PRIVATA" value={8} onChange = {handleChangeDetail} checked = {isChecked(8)}/>
                <span>SERVIZIO EDILIZIA PRIVATA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="POLIZIA URBANA, SICUREZZA E PROTEZIONE CIVILE" value={9} onChange = {handleChangeDetail} checked = {isChecked(9)}/>
                <span>POLIZIA URBANA, SICUREZZA E PROTEZIONE CIVILE</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="CASA" value={10} onChange = {handleChangeDetail} checked = {isChecked(10)}/>
                <span>CASA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="PARI OPPORTUNITA', POLITICHE DEI TEMPI, VOLONTARIATO, POLITICHE SOCIALI" value={11} onChange = {handleChangeDetail} checked = {isChecked(11)}/>
                <span>PARI OPPORTUNITA', POLITICHE DEI TEMPI, VOLONTARIATO, POLITICHE SOCIALI</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="CINEMA-TEATRO CESARE VOLTA" value={12} onChange = {handleChangeDetail} checked = {isChecked(12)}/>
                <span>CINEMA-TEATRO CESARE VOLTA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="CULTURA, TURISMO E SPORT" value={13} onChange = {handleChangeDetail} checked = {isChecked(13)}/>
                <span>CULTURA, TURISMO E SPORT</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="SERVIZI E MODULISTICA" value={14} onChange = {handleChangeDetail} checked = {isChecked(14)}/>
                <span>SERVIZI E MODULISTICA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="AMBIENTE E RIFIUTI" value={15} onChange = {handleChangeDetail} checked = {isChecked(15)}/>
                <span>AMBIENTE E RIFIUTI</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="EMERGENZA COVID 19" value={16} onChange = {handleChangeDetail} checked = {isChecked(16)}/>
                <span>EMERGENZA COVID 19</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="UFFICIO EUROPA" value={17} onChange = {handleChangeDetail} checked = {isChecked(17)}/>
                <span>UFFICIO EUROPA</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="BENESSERE E TUTELA DEGLI ANIMALI" value={18} onChange = {handleChangeDetail} checked = {isChecked(18)}/>
                <span>BENESSERE E TUTELA DEGLI ANIMALI</span>
              </div>
              <div className="text-left">
                <input type="checkbox" name="INTRANET" value={100} onChange = {handleChangeDetail} checked = {isChecked(100)}/>
                <span>INTRANET</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <label className="form__label">Note:</label>
            <div className="text-left">
              <textarea className="form__textarea mb-0" name="notes" value={eventDetail.notes} onChange = {handleChangeDetail}/>
              <div className="form__info">
                <em>
                  ATTENZIONE: il campo note serve ESCLUSIVAMENTE alla descrizione delle modifiche effettuate, 
                  in caso di modifica dell'evento, e se non compilato la modifica NON potrà essere applicata.
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-contenuto__wrapper text-center mb-3">
        <button type="submit" className="form__submit ml-2">
          {loading &&<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
          Avanti  
        </button>
      </div>
    </>
  );
}

export default FormInputs;