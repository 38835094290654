import React from 'react';
import { Router } from "@reach/router";
import Header from "./components/header";
import Navigation from './components/navigation';
import Eventi from './eventi';
import Istruzioni from './istruzioni';
import Modifica from './modifica';
import Evento from './evento';
import Esporta from './esporta';
import Amministratori from './amministratori';
import Utenti from './utenti';
import NotFound from './notFound';


const Admin = () => {
  let date = new Date();

  return (
    <>
      <Header headerText="Gestione Contenuti - ADMIN" />
      <Navigation/>
      <Router>
        <Eventi path="/" start={Date.now()}/>
        <Modifica path="modifica/:eventId"/>
        <Evento path="evento/:eventId"/>
        <Esporta path="esporta" start={date.getTime()}/>
        <Amministratori path="amministratori" start={date.getTime()}/>
        <Utenti path="utenti" start={date.getTime()}/>
        <Istruzioni path="istruzioni"/>
        <NotFound path="/notfound" default errorMessage="Pagina non trovata."/>
      </Router>
    </>
  );
}

export default Admin;