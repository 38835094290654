import React, { useState, useEffect, useCallback } from 'react';
import BoxAvviso from './components/box-avviso';
import EventTable from './components/event-table';
import { useAuth } from "./context/auth";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const Gestisci = () => {
  const [arrayEvents, setArrayEvents] = useState([]);
  const [isRenderedList, setIsRenderedList] = useState(false);
  const [loading, setLoading] = useState({send: false, delete: false});
  const { showEventsList, deleteEvent, sendEvent} = useAuth();

  const clickDelete = async (i) => {
    if(window.confirm("Eliminare il contenuto e gli eventuali file allegati?")) {
      setLoading({...loading, delete: i});
      let data = await deleteEvent({id: i});
      if(data.error) {
        alert("Errore: ", data.error);
      }
      setIsRenderedList(false);
      setLoading({...loading, delete: false});
    }
  }

  const clickSend = async(i) => {
    // trova l'indice corrispondente alla chiave dell'elemento cliccato
    if(window.confirm("Inviare questo il contenuto alla redazione?")) {
      setLoading({...loading, send: i});
      let data = await sendEvent({id: i});
      if(data.error) {
        alert("Errore: " + data.error);
      }
      setIsRenderedList(false);
      setLoading({...loading, send: false});
    }
  }

  const callApi = useCallback(async()=> {
    let data = await showEventsList();
    if(data.error) {
      alert("Errore: " + data.error)
    } else {
      setArrayEvents(data.events);
    }
    setIsRenderedList(true);
  }, [showEventsList]);

  useEffect(()=> {
    if(!isRenderedList) {
      callApi();
    }
  },[isRenderedList, callApi]);

  // ordino gli eventi per data
  arrayEvents && arrayEvents.sort((date1, date2) => {
    if(dayjs(date1.startDate + " " + date1.startTime,"YYYY/MM/DD hh:mm").isAfter(dayjs(date2.startDate + " " + date2.startTime,"YYYY/MM/DD hh:mm"),'minute')) {
      return 1;
    } else if (dayjs(date1.startDate + " " + date1.startTime,"YYYY/MM/DD hh:mm").isBefore(dayjs(date2.startDate + " " + date2.startTime,"YYYY/MM/DD hh:mm"),'minute')){
      return -1;
    }
    return 0;
  });

  return (
    <>
      {
        isRenderedList &&
        <>
          <BoxAvviso />
          {/* eventi non ancora trasmessi */}
          <div className="box-contenuto">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {
                    arrayEvents && (
                      <div className="box-contenuto__wrapper">
                        <div className="box-contenuto__title">
                          » Elenco dei tuoi eventi non ancora trasmessi alla redazione «
                        </div>
                        <EventTable arrayEvents = {arrayEvents.filter((k) => k.sent === false)} deleteEvent = {clickDelete} sendEvent = {clickSend} loading = {loading}/>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          {/* eventi trasmessi alla redazione */}
          <div className="box-contenuto">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {
                    arrayEvents && (
                      <div className="box-contenuto__wrapper box-contenuto__wrapper--yellow">
                        <div className="box-contenuto__title">
                          » Elenco degli ultimi tuoi eventi inseriti e trasmessi alla redazione «
                        </div>
                        <EventTable arrayEvents = {arrayEvents.filter((k) => k.sent === true)}  deleteEvent = {clickDelete} sendEvent = {clickSend} loading = {loading}/>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Gestisci;
