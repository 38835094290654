import { createContext, useContext, useState, useCallback} from 'react';

export const AuthContext = createContext();

const createEvent = (token, data) => {
  return fetch('/api/create-event', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

const showEvent = (token) => {
  return fetch('/api/events', {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
}

const modificaEvento = (token, data) => {
  return fetch('/api/update-event', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

const eliminaEvento = (token, data) => {
  return fetch('/api/delete-event', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

const inviaRedazione = (token, data) => {
  return fetch('/api/send-event', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

const getEventById = (token, data) => {
  return fetch(('/api/event'+data), {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
}

const prepareUpload = (token, {id, filename}) => {
  return fetch(('/api/prepare-upload'), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({id, filename})
  })
}

const confirmUpload = (token, {fileId, fileName, description}) => {
  return fetch(('/api/confirm-upload'), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({fileId, fileName, description})
  })
}

const deleteAttachment = (token, data) => {
  return fetch('/api/delete-file', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

const getFileUrl = (token, data) => {
  return fetch(('/api/file-url'+ data), {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
}

// api admin
const listAdminEvents = (token, data) => {
  return fetch(('/api/admin-events'+data), {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
}

const listAdminUnsentEvents = (token) => {
  return fetch('/api/admin-unsent-events', {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
}

const adminSync = (token, data) => {
  return fetch('/api/admin-sync-event', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

const listUsers = (token, data) => {
  return fetch(('/api/list-users'+data), {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  })
}

const promote = (token, data) => {
  return fetch('/api/admin-promote', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },    
    body: JSON.stringify(data)
  })
}

export const useAuthData = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const isTokenValid = useCallback(async ()=> {
    try {
      const response = await fetch('/api/token', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: localStorage.getItem("username"),
          refreshToken: localStorage.getItem("refreshToken")
        })
      });
      if (response.status === 401) {
        return false;
      } else {
        let data = await response.json();
        setToken(data.token);
        setUser(data.user);
        localStorage.setItem("refreshToken", data.refreshToken);
        console.log("Access e refresh tokens aggiornati");
        return true;
      }
    } catch (err) {
      console.log("Errore!", err);
      return false;
    }
  }, [setToken, setUser])

  const getNewToken = useCallback(async () => {
    const response = await fetch('/api/token', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        refreshToken: localStorage.getItem("refreshToken")
      })
    });
    const data = await response.json();
    setToken(data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
    console.log("Ora che il token è aggiornato, dovrebbe richiamare il servizio...");
    return data.token;
  }, []);

  const callService = useCallback(async(fun, data) => {
    try { 
      const response = await fun(token, data);
      if(response.status === 401) {
        console.log("Richiedendo un nuovo token...");
        const newToken = await getNewToken();
        const res = await fun(newToken, data);
        return await res.json();
      } else {
        return response.json();
      }
    } catch (err) {
      console.log("Errore!", err);
    }
  }, [token, getNewToken]);

  const createNewEvent = (data) => {
    return callService(createEvent, data);
  };

  const showEventsList = () => {
    return callService(showEvent);
  };

  const modifyEvent = (data) => {
    return callService(modificaEvento, data);
  };

  const deleteEvent = (data) => {
    return callService(eliminaEvento, data);
  };

  const sendEvent = (data) => {
    return callService(inviaRedazione, data);
  };

  const getEvent = (data) => {
    return callService(getEventById, data);
  };

  const prepareFileUpload = (data) => {
    return callService(prepareUpload, data);
  };

  const confirmFileUpload = (data) => {
    return callService(confirmUpload, data);
  };

  const deleteAtt = (data) => {
    return callService(deleteAttachment, data);
  };

  const getUrl = (data) => {
    return callService(getFileUrl, data);
  };

  // api amministratore
  const eventsAdmin = (data) => {
    return callService(listAdminEvents, data);
  };
  
  const eventsUnsentAdmin = () => {
    return callService(listAdminUnsentEvents);
  };
  
  const adminSyncEvent = (data) => {
    return callService(adminSync, data);
  };

  const showUsersList = (data) => {
    return callService(listUsers, data);
  }

  const promoteUser = (data) => {
    return callService(promote, data);
  }

  return {
    user, 
    setUser,
    token,
    setToken,
    isTokenValid,
    createNewEvent,
    showEventsList,
    modifyEvent,
    deleteEvent,
    sendEvent,
    getEvent,
    prepareFileUpload,
    confirmFileUpload,
    deleteAtt,
    getUrl,
    eventsAdmin,
    eventsUnsentAdmin,
    adminSyncEvent,
    showUsersList,
    promoteUser
  };
}

export const useAuth = () => {
  return useContext(AuthContext);
}
