const convertCategory = (num) =>  {
    switch(num) {
        case 1: return "AMMINISTRAZIONE";
        case 2: return "MOBILITA' E TRASPORTI";
        case 3: return "SCUOLA, GIOVANI E FAMIGLIA";
        case 4: return "TASSE E TRIBUTI";
        case 5: return "DUC";
        case 6: return "COMMERCIO E ATTIVITA' PRODUTTIVE";
        case 7: return "LAVORI PUBBLICI E URBANISTICA";
        case 8: return "SERVIZIO EDILIZIA PRIVATA";
        case 9: return "POLIZIA URBANA, SICUREZZA E PROTEZIONE CIVILE";
        case 10: return "CASA";
        case 11: return "PARI OPPORTUNITA', POLITICHE DEI TEMPI, VOLONTARIATO, POLITICHE SOCIALI";
        case 12: return "CINEMA-TEATRO CESARE VOLTA";
        case 13: return "CULTURA, TURISMO E SPORT";
        case 14: return "SERVIZI E MODULISTICA";
        case 15: return "AMBIENTE E RIFIUTI";
        case 16: return "EMERGENZA COVID 19";
        case 17: return "UFFICIO EUROPA";
        case 18: return "BENESSERE E TUTELA DEGLI ANIMALI";
        case 100: return "INTRANET";
        default: return "";
    }
}

export default convertCategory;