import React, {useRef} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const EditorDocument = ({handleChangeTextArea, description}) => {
  const toolbarRef = useRef(null);
  const config = {
    toolbar: {
      items: [
        'heading', '|',
        'alignment', '|',
        'bold', 'italic', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', '|',
        'cut', 'copy', 'paste',
        'undo', 'redo', 'removeFormat'
      ],
    shouldNotGroupWhenFull: true
    },
    language: 'it',
    disableAutoInline: true
  }

  return (
    <div className="">
      <div ref={toolbarRef} id="toolbar-container"></div>
      <CKEditor
        editor = { Editor }
        config = {config}
        data={description || ""}
        onChange={ ( event, editor ) => {
          const data = editor.getData();
          handleChangeTextArea(data);
        }}
        onReady={ editor => {
          // Add the toolbar to the container
          toolbarRef.current.appendChild( editor.ui.view.toolbar.element );
        }}
      />
    </div>
  );
}

export default EditorDocument;
