import React from 'react';
import { useAuth } from "./context/auth";


const linkIstruzioni = "https://app-comune-pavia-files-prod.s3-eu-west-1.amazonaws.com/static/Istruzioni.pdf";

const Istruzioni = () => {
  const { user } = useAuth();

  return (
    <div className="box-contenuto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-contenuto__wrapper">
              <div className="box-contenuto__text-gray">
                {
                  user.role === "admin" ? 
                  <strong>Istruzioni sulle modalità di compilazione dei campi di PCDQ.</strong>:
                  <strong>Istruzioni sulle modalità d'uso di questa applicazione.</strong>
                }
              </div>
              <div className="p-3">
                {
                  user.role === "admin" ? 
                  <>
                  <ol className="mb-2">
                    <li>DATA INIZIO: obbligatorio, cliccare per selezionare da calendario.</li>
                    <li>ORARIO INIZIO : obbligatorio, ciccare  sopra il campo e sceglie l'ora.</li>
                    <li>DATA FINE: obbligatorio, cliccare per selezionare da calendario.</li>
                    <li>ORARIO FINE:  facoltativo, non compilare se non è previsto un orario di fine evento.</li>
                    <li>EVENTO RICORSIVO:  selezionare l'opzione adeguata lasciare selezionato NO.</li>
                    <li>NOME:  obbligatorio, indicare con precisione la denominazione del luogo dell'evento.</li>
                    <li>VIA E NUMERO CIVICO: obbligatorio, indicare con massima precisione SOLO via e numero civico (NON inserire il COMUNE, questo dato deve consentire la geolocalizzazione del luogo su google map).</li>
                    <li>COMUNE: obbligatorio.</li>
                    <li>TITOLO: obbligatorio.</li>
                    <li>DESCRIZIONE: obbligatorio.</li>
                  </ol>
                  <p>
                    Se vuoi, puoi anche&nbsp;
                    <a href={linkIstruzioni} target="_blank" rel="noopener noreferrer">prelevare questo file</a>
                    &nbsp;in formato PDF.
                  </p>
                  </>
                   :
                  <p className="mb-2">
                    <a href={linkIstruzioni} target="_blank" rel="noopener noreferrer">Preleva il file</a>
                    &nbsp;in formato PDF.
                  </p>
                }
                <p className="mb-2">
                  Per eventuali problemi, puoi contattare la Redazione all'indirizzo&nbsp;
                  <a href="mailto:redazione@comune.pv.it">redazione@comune.pv.it</a>
                  &nbsp;o al n. 0382 399249.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Istruzioni;
