import React, {useState, useEffect, useCallback} from 'react';
import EventTable from './components/event-table';
import { useAuth } from "./context/auth";
import { navigate} from '@reach/router';

const Evento = ({location}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialState, setInitialState] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({sync: false, syncIntra: false, delete: false});

  const {getEvent, deleteEvent, sendEvent, adminSyncEvent} = useAuth();
  let currentId = location.pathname.split("/").reverse()[0];

  const callApi = useCallback(async(id)=> {
    let data = await getEvent("?id="+id);
    if(data.error) {
      setError(data.error);
    } else {
      setInitialState([data.event]);
      setError(false);
    }
    setIsLoading(false);
  }, [getEvent]);

  const clickDelete = async (i) => {
    if(window.confirm("Eliminare il contenuto e gli eventuali file allegati?")) {
      setLoading({...loading, delete: i});
      let data = await deleteEvent({id: i});
      if(data.error) {
        setError(data.error);
        setLoading({...loading, delete: false});
      } else {
        setError(false);
        setLoading({...loading, delete: false});
        navigate("/admin");
      }
    }
  }

  const clickSend = async(i) => {
    // trova l'indice corrispondente alla chiave dell'elemento cliccato
    if(window.confirm("Inviare questo il contenuto alla redazione?")) {
      let data = await sendEvent({id: i});
      if(data.error) {
        setError(data.error);
      } else {
        setError(false);
      }
    }
  }

  const clickSync = async (eventId, intra = false ) => {
    if(window.confirm("Sincronizzare l'evento?")) {
      if(intra) {
        setLoading({...loading, syncIntra: eventId});
      } else {
        setLoading({...loading, sync: eventId});
      }
      let data = await adminSyncEvent({id: eventId, intra });
      if(data.error) {
        setLoading({...loading, syncIntra: false, sync: false});
        setError(data.error);
      } else {
        setLoading({...loading, syncIntra: false, sync: false});
        setIsLoading(true);
      }
    }
  }

  useEffect(()=> {
    if(isLoading) {
      callApi(currentId);
    }
  },[isLoading, callApi, currentId]);

  return (
    <>
      {
        !isLoading && 
        <div className="box-contenuto">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {error ?
                  <>Errore: {error}</> :
                  <div className="box-contenuto__wrapper">
                    <EventTable arrayEvents = {initialState} deleteEvent = {clickDelete} sendEvent = {clickSend} syncEvent={clickSync} loading = {loading}/>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Evento;
