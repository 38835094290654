import React from 'react';
import Form from './components/form';

const Nuovo = () => {

  const initialState = {
    startDate: null,
    startTime : null,
    endDate : null,
    endTime : null,
    place: "",
    address: "",
    city: "",
    title: "",
    description: "",
    notes: "",
    categories: [],
    attachmentsList: []
  };

  return (
    <>
      <Form start={Date.now()} initialState = {initialState} initialId = {null} />
    </>
  );
}

export default Nuovo;
