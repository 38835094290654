import React from 'react';
import { Router } from "@reach/router";
import PaginaLogin from "./paginaLogin";
import Redattore from "./redattore";
import Admin from "./admin";
import Home from "./home";
import NotFound from "./notFound";
import PrivateRoute from "./components/private-route";
import { AuthContext, useAuthData } from "./context/auth";
import './css/custom.scss';


function App() {
  const authData = useAuthData();

  return (
    <AuthContext.Provider value={authData}>
      <Router>
        <Home path="/"/>
        <PaginaLogin path="/login"/>
        <PrivateRoute path="redattore/*" component={Redattore}/>
        <PrivateRoute path="admin/*" component={Admin}/>
        <NotFound path="notfound" header={true} errorMessage="Pagina non trovata." default/>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
