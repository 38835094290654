import React, {useState, useEffect, useCallback} from 'react';
import Form from './components/form';
import { useAuth } from "./context/auth";

const Modifica = ({location}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialState, setInitialState] = useState(null);
  const [error, setError] = useState(null);
  const { getEvent } = useAuth();
  let currentId = location.pathname.split("/").reverse()[0];

  const callApi = useCallback(async(id)=> {
    let data = await getEvent("?id="+id);
    if(data.error) {
      setError(data.error);
    } else {
      setInitialState(data.event);
      setError(false);
    }
    setIsLoading(false);
  }, [getEvent]);

  useEffect(()=> {
    if(isLoading) {
      callApi(currentId);
    }
  },[isLoading, callApi, currentId]);

  return (
    <>
      {
        !isLoading && (
          error ?
          <div className="box-contenuto">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  Errore: {error}
                </div>
              </div>
            </div>
          </div>  :
          <Form initialState = {initialState} initialId = {currentId} />
        )
      }
    </>
  );
}

export default Modifica;
