import React from 'react';
import logo from '../images/logo.png';
import { useAuth } from "../context/auth";


const Header = ({headerText}) => {
  const { user } = useAuth();
  let textClass = user && user.ente ? "font-weight-bold" : "";
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header__wrapper">
              <img src={logo} alt="logo" />
              {user && (
                <div className="header__text">
                  {
                    user && user.role === "admin" ?
                    <>{headerText}</>:
                    <>
                      <span className={textClass}>{user.name} {user.surname}</span> 
                      {
                        user.ente &&
                        " ("+user.ente+")"
                      }
                    </>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;