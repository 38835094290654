import React from 'react';

const TabellaUtenti = ({arrayUtenti, clickPromote, loading}) => {
  return (
    <div className="box-contenuto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="box-contenuto__wrapper">
              <table className="users-table">
                <thead>
                  <tr>
                    <th>
                      Cognome
                    </th>
                    <th>
                      Nome
                    </th>
                    <th>
                      Ente
                    </th>
                    <th>
                      Email
                    </th>
                    <th>
                      Tel/Cell
                    </th>
                    <th width="40">
                      Admin
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    arrayUtenti.length > 0 ? 
                    <>
                    {
                      arrayUtenti.map((utente, index) => (
                        <tr key = {index}>
                          <td>
                            {utente.surname}
                          </td>
                          <td>
                            {utente.name}
                          </td>
                          <td>
                            {utente.ente}
                          </td>
                          <td>
                            {utente.email}
                          </td>
                          <td>
                            {utente.tel &&
                              <div>T. {utente.tel}</div>
                            }
                            {utente.cel &&
                              <div>C. {utente.cel}</div>
                            }
                          </td>
                          <td>
                            <button type="button" onClick = {() => clickPromote(utente.username)} className="users-table__btn">
                              {loading && loading.promote === utente.username ? 
                                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> :
                                utente.role === "admin" ? <>Sì</>: <>No</>
                              }
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                    </> :
                    <tr className="noevents">
                      <td colSpan="8" align="center">Nessun utente.</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabellaUtenti;