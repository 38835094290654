import React, { useState, useEffect, useCallback } from 'react';
import TabellaUtenti from './components/tabella-utenti';
import { useAuth } from "./context/auth";

const Amministratori = ({start}) => {

  const [arrayUtenti, setArrayUtenti] = useState([]);
  const [isRendered, setIsRendered] = useState(false);

  const { showUsersList } = useAuth();

   const callApiUsers = useCallback(async()=> {
     let res = await showUsersList("?role=admin");
     setArrayUtenti(res.users);
     setIsRendered(true);
   }, [showUsersList]);

  useEffect(()=> {
    if(start) {
      callApiUsers();
    }
  }, [start, callApiUsers]);

 
  return (
    <>
      {isRendered &&
        <TabellaUtenti arrayUtenti = {arrayUtenti} />
      }
    </>
  );
}

export default Amministratori;
